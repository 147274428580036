<template>
  <div class=" ">
    <!-- BEGIN: HTML Table Data -->
    <!-- step1輸入公司統一編號 -->
    <div v-if="step === 1" class="intro-y box p-10 mt-10">
      <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">
        建立供應商企業資料
      </div>
      <!-- <div class="font-bold text-2xl xl:text-3xl my-5">查詢要申請加入的供應商</div> -->
      <div class="mb-20">
        <VForm
          ref="companyForm"
          :validation-schema="validationSchema"
          :initial-values="formData"
          @submit="submit"
        >
          <div class=" flex items-center">
            <div class="w-2/3 sm:w-96">
              <label class="font-medium text-lg"
                >公司統編<span class=" text-red-400">＊</span></label
              >
              <CfInputBox
                name="taxId"
                placeholder="公司統編"
                maxLength="8"
                :modelValue="formData.taxId"
                @update:modelValue="
                  val => {
                    formData.taxId = val;
                  }
                "
                :showMessage="true"
                type="text"
              ></CfInputBox>
            </div>
            <div class=" ">
              <button
                type="submit"
                class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 mt-6 ml-3"
              >
                查詢
              </button>
            </div>
          </div>
        </VForm>
      </div>
      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="/company/application"
            class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
          >
            回上一步
          </a>
        </div>
      </div>
    </div>

    <!-- step2確認資料 -->
    <div v-if="step === 2" class=" intro-y box p-10 mt-10">
      <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">
        建立供應商企業資料
      </div>
      <div class="mb-10 border-b border-theme-5">
        <div class=" md:flex items-end mb-10">
          <div class="w-full sm:w-96">
            <label class=" font-medium text-lg">公司統編</label>
            <input
              type="text"
              class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-1"
              :value="applyData.company.taxID"
              disabled
            />

            <!-- <div class=" text-gray-600 text-base">
              {{ applyData.company.taxID }}
            </div> -->
          </div>
          <!-- <div class="w-full text-gray-600 ml-2 mb-4">
            您已經是軟協會員，已為您確認相關資料，請送出申請。
            您不是軟協會員，請送出申請，我們將核對您的相關資料。
          </div> -->
        </div>
      </div>

      <div class="mb-20">
        <div class="lg:grid grid-cols-2 gap-10">
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >公司名稱<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.name }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg">公司英文名稱</label>
            <div class=" text-gray-600 text-base">
              {{ applyData.company.name_Eng }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >核准設立日期<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.setup_Date }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >實收資本額（元）<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.capital_Stock_Amount }}
            </div>
          </div>
          <div class=" col-span-2 mb-5">
            <label class=" font-medium text-lg"
              >公司登記地址<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.address }}
            </div>
          </div>
          <div class=" col-span-1 mb-5">
            <label class=" font-medium text-lg"
              >資本額（元）<span class=" text-red-400">＊</span></label
            >
            <div class=" text-gray-600 text-base">
              {{ applyData.company.paid_In_Capital_Amount }}
            </div>
          </div>
          <div class=" col-span-2 mb-5">
            <label class=" font-medium text-lg">公司登記英文地址</label>
            <div class=" text-gray-600 text-base">
              {{ applyData.company.address_Eng }}
            </div>
          </div>
        </div>
      </div>

      <div class=" flex justify-end items-center">
        <div class=" flex items-center">
          <a
            href="javascript:;"
            @click="back"
            class="button text-lg px-10 block text-theme-1 bg-transparent mr-2"
          >
            上一步
          </a>
          <!-- <button
            type="type"
            class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 ml-3"
            @click="applySubmit"
          >
            送出申請
          </button> -->
          <a
            href="javascript:;"
            @click="step = 3"
            class="button text-lg px-10 block text-white shadow-md bg-theme-1 mr-2"
          >
            下一步
          </a>
        </div>
      </div>
    </div>

    <!-- step3確認資料 -->
    <div v-if="step === 3" class="intro-y box p-10 mt-10">
      <VForm
        ref="joinForm"
        :validation-schema="joinValidationSchema"
        :initial-values="applyData.user"
        @submit="applySubmit"
      >
        <div class="font-bold text-2xl xl:text-3xl mt-5 mb-10">
          建立供應商企業資料
        </div>

        <div class="mb-20">
          <div class=" mb-5">
            <label class=" font-medium text-lg mb-2 block">公司名稱</label>
            <div class="text-base">{{ applyData.company.name }}</div>
          </div>
          <div class=" mb-5">
            <label class=" font-medium text-lg mb-2 block">申請人</label>
            <div class="text-base">{{ applyData.user.name }}</div>
          </div>
          <div class="mb-5 flex items-center">
            <div class="w-2/3 sm:w-96">
              <label class="font-medium text-lg">業務聯絡信箱</label>
              <CfInputBox
                name="email"
                placeholder="asdfff@cisa.com"
                type="r"
                v-model="applyData.user.email"
                :disabled="false"
              ></CfInputBox>
            </div>
            <!-- <div class=" ">
              <button
                type="button"
                class="button items-center button--lg w-full lg:w-32 text-white bg-theme-1 align-top lg:mb-0 mt-6 ml-3"
              >
                修改
              </button>
            </div> -->
          </div>

          <div class=" mb-5">
            <label class=" font-medium text-lg mb-2">身份</label>
            <div class="text-base mb-3">
              <CfInputRadioBox
                id="principal"
                name="Identity"
                defaultValue="1"
                :modelValue="userIdentity"
                @update:modelValue="
                  val => {
                    userIdentity = val;
                  }
                "
              ></CfInputRadioBox>
              <label class="cursor-pointer select-none" for="principal">
                公司代表人、董監事、經理人
              </label>
            </div>
            <div class="text-base mb-3">
              <CfInputRadioBox
                id="assistant"
                name="Identity"
                defaultValue="2"
                :modelValue="userIdentity"
                @update:modelValue="
                  val => {
                    userIdentity = val;
                  }
                "
              ></CfInputRadioBox>
              <label class="cursor-pointer select-none" for="assistant">
                助理與特助
              </label>
            </div>
            <div class="text-base mb-3">
              <CfInputRadioBox
                id="sales"
                name="Identity"
                defaultValue="3"
                :modelValue="userIdentity"
                @update:modelValue="
                  val => {
                    userIdentity = val;
                  }
                "
              ></CfInputRadioBox>
              <label class="cursor-pointer select-none" for="sales">
                業務負責人
              </label>
            </div>
            <div class="text-base mb-3">
              <CfInputRadioBox
                id="other"
                name="Identity"
                defaultValue="4"
                :modelValue="userIdentity"
                @update:modelValue="
                  val => {
                    userIdentity = val;
                  }
                "
              ></CfInputRadioBox>
              <label class="cursor-pointer select-none" for="other">
                其他：
              </label>
              <div class="w-full">
                <CfInputBox
                  name="otherIdentity"
                  placeholder="輸入職稱"
                  :modelValue="applyData.user.otherIdentity"
                  @update:modelValue="
                    val => {
                      applyData.user.otherIdentity = val;
                    }
                  "
                  :showMessage="true"
                  type="text"
                  :disabled="userIdentity != 4"
                  class="sm:w-40"
                ></CfInputBox>
                <!-- <input
                  id="otherIdentity"
                  name="otherIdentity"
                  placeholder="輸入職稱"
                  type="text"
                  class="intro-x input
                    input--lg border border-gray-300  mt-2 sm:w-40 ml-5"
                  :disabled="userIdentity != 4"
                /> -->
              </div>
            </div>
            <div class="text-base mb-3 fileUploadStyle">
              <div id="cardUpload" v-if="userIdentity !== '1'">
                <label class=" font-medium text-lg"
                  >上傳名片<span class=" text-red-400">＊</span></label
                >
                <div class="w-full sm:w-96">
                  <!-- <CfInputBox
                    name="user_card"
                    placeholder="請上傳公司名片影像(須包含姓名、公司名稱及email)"
                    type="file"
                    class=""
                    :modelValue="applyData.user.user_card"
                    @update:modelValue="
                      val => {
                        applyData.user.user_card = val;
                      }
                    "
                  ></CfInputBox> -->
                  <div>
                    <div>
                      <small class=" text-gray-600 text-sm"
                        >請上傳公司名片影像(須包含姓名、公司名稱及email)</small
                      >
                    </div>
                    <file-uploader
                      :ref="el => (uploader = el)"
                      :headers="headers"
                      :data="imageData"
                      id="card-photo-uri"
                      style="min-width: 400px; max-width: 400px; min-height: 250px; max-height: 250px;"
                      class="mb-10"
                      mode="image"
                      :modelValue="applyData.user.user_card"
                      :action="uploadAction"
                      :autoUpload="true"
                      :limitedWidth="400"
                      :limitedHeight="250"
                      @update:modelValue="uploadResult"
                      @filter="uploaderFilter"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class=" flex justify-end items-center">
          <div class=" flex items-center">
            <a
              href="javascript:;"
              @click="step = 2"
              class="button items-center button--lg w-32 text-theme-1 bg-transparent align-top lg:mb-0"
            >
              上一步
            </a>

            <button
              type="submit"
              class="button items-center button--lg w-32 text-white bg-theme-1 align-top lg:mb-0 ml-3"
            >
              送出申請
            </button>
          </div>
        </div>
      </VForm>
    </div>

    <!-- step4 資料送出-->
    <div v-if="step === 4" class="intro-y box p-10 mt-10 h-96 content-center">
      <div class="item-center w-full h-full text-center">
        <div class="text-2xl">您的申請已完成</div>
        <div class="text-lg">平台正在為您開通功能</div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
export default defineComponent({
  components: {
    CfInputBox,
    VForm: Form,
    CfInputRadioBox,
    FileUploader
  },
  setup() {
    const formData = reactive({ taxId: "" });
    const companyForm = ref();
    const joinForm = ref();
    const userIdentity = ref("1");
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const model = CloudFun.current?.model;
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const applyData = reactive({
      user: {
        user_card: "",
        user_card_id: "",
        contact_Tel: "",
        contact_Title: "",
        position: 1,
        email: "",
        name: "",
        otherIdentity: ""
      },
      company: {}
    });
    const step = ref(1);
    const validationSchema = Yup.object().shape({
      taxId: Yup.string()
        .min(8, "請輸入正確統一編號")
        .max(8, "請輸入正確統一編號")
        .required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Email 格式不正確")
        .required("必要欄位"),
      contact_Tel: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      otherIdentity: Yup.string().test("otherIdentity", "必要欄位", value => {
        if (userIdentity.value === "4")
          return value != null && value.length > 0;
        return true;
      }),
      user_card: Yup.string().test("user_card", "必要欄位", (value, schema) => {
        if (userIdentity.value != "1") return value != null && value.length > 0;
        return true;
      })
    });

    const loadUserData = async () => {
      const userData = model?.dispatch("user/find");
      return userData;
    };

    const getStatus = async () => {
      const status = model?.dispatch("vendor/status");
      return status;
    };

    watch(userIdentity, current => {
      console.log(
        "🚀 ~ file: Create.vue ~ line 402 ~ watch ~ current",
        current
      );
    });

    onMounted(async () => {
      const status = await getStatus();
      if (status.payload.isApplying) {
        step.value = 4;
      } else {
        const data = await loadUserData();
        console.log(
          "🚀 ~ file: Create.vue ~ line 415 ~ onMounted ~ data",
          data
        );
        applyData.user.name = data.payload.name;
        applyData.user.email = data.payload.email;
      }
    });

    return {
      validationSchema,
      formData,
      companyForm,
      applyData,
      step,
      joinForm,
      userIdentity,
      joinValidationSchema,
      headers,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    submit(data: any) {
      console.log("submit", data);
      this.$model.dispatch("vendor/findByGCIS", data).then(
        response => {
          if (response.payload) {
            console.log(
              "🚀 ~ file: Create.vue ~ line 502 ~ submit ~ payload",
              response.payload
            );
            this.applyData.company = response.payload;

            this.step = 2;
          } else {
            CloudFun.send("error", {
              subject: "找不到資料",
              content: "請檢查輸入統編是否正確"
            });
          }
        },
        reason => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: reason
          });
        }
      );
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    uploadResult(value: any) {
      console.log(
        "🚀 ~ file: Create.vue ~ line 523 ~ uploadResult ~ value",
        value
      );

      if (value?.archiveID) {
        this.applyData.user.user_card_id = value?.archiveID;
      } else if (value == "delete") this.applyData.user.user_card_id = "";
    },
    applySubmit() {
      const data = {
        ...this.applyData.company,
        ...this.applyData.user
      } as any;
      delete data.email;
      console.log(
        "🚀 ~ file: CreateCompany.vue ~ line 446 ~ applySubmit ~ payload",
        data
      );

      if (data) {
        CloudFun.send("success", {
          subject: "申請送出",
          content: "成功(API 缺欄位，目前等待調整中....)"
        });
        this.step = 4;
        return;
        this.$model.dispatch("vendor/apply", data).then(
          response => {
            console.log(
              "🚀 ~ file: Create.vue ~ line 471 ~ applySubmit ~ response",
              response
            );

            if (response.status && response.payload.isPassed) {
              CloudFun.send("success", {
                subject: "申請送出",
                content: "成功"
              });
              this.step = 4;
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: response.message
              });
            }
          },
          reason => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: reason
            });
          }
        );
      }
    }
  }
});
</script>
