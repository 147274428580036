
/* eslint-disable */

import { ref, reactive, onMounted, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
export default defineComponent({
  components: {
    CfInputBox,
    VForm: Form,
    CfInputRadioBox,
    FileUploader
  },
  setup() {
    const formData = reactive({ taxId: "" });
    const companyForm = ref();
    const joinForm = ref();
    const userIdentity = ref("1");
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const model = CloudFun.current?.model;
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const applyData = reactive({
      user: {
        user_card: "",
        user_card_id: "",
        contact_Tel: "",
        contact_Title: "",
        position: 1,
        email: "",
        name: "",
        otherIdentity: ""
      },
      company: {}
    });
    const step = ref(1);
    const validationSchema = Yup.object().shape({
      taxId: Yup.string()
        .min(8, "請輸入正確統一編號")
        .max(8, "請輸入正確統一編號")
        .required("必要欄位")
    });

    const joinValidationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Email 格式不正確")
        .required("必要欄位"),
      contact_Tel: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      otherIdentity: Yup.string().test("otherIdentity", "必要欄位", value => {
        if (userIdentity.value === "4")
          return value != null && value.length > 0;
        return true;
      }),
      user_card: Yup.string().test("user_card", "必要欄位", (value, schema) => {
        if (userIdentity.value != "1") return value != null && value.length > 0;
        return true;
      })
    });

    const loadUserData = async () => {
      const userData = model?.dispatch("user/find");
      return userData;
    };

    const getStatus = async () => {
      const status = model?.dispatch("vendor/status");
      return status;
    };

    watch(userIdentity, current => {
      console.log(
        "🚀 ~ file: Create.vue ~ line 402 ~ watch ~ current",
        current
      );
    });

    onMounted(async () => {
      const status = await getStatus();
      if (status.payload.isApplying) {
        step.value = 4;
      } else {
        const data = await loadUserData();
        console.log(
          "🚀 ~ file: Create.vue ~ line 415 ~ onMounted ~ data",
          data
        );
        applyData.user.name = data.payload.name;
        applyData.user.email = data.payload.email;
      }
    });

    return {
      validationSchema,
      formData,
      companyForm,
      applyData,
      step,
      joinForm,
      userIdentity,
      joinValidationSchema,
      headers,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`
    };
  },
  methods: {
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1;
    },
    submit(data: any) {
      console.log("submit", data);
      this.$model.dispatch("vendor/findByGCIS", data).then(
        response => {
          if (response.payload) {
            console.log(
              "🚀 ~ file: Create.vue ~ line 502 ~ submit ~ payload",
              response.payload
            );
            this.applyData.company = response.payload;

            this.step = 2;
          } else {
            CloudFun.send("error", {
              subject: "找不到資料",
              content: "請檢查輸入統編是否正確"
            });
          }
        },
        reason => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: reason
          });
        }
      );
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    uploadResult(value: any) {
      console.log(
        "🚀 ~ file: Create.vue ~ line 523 ~ uploadResult ~ value",
        value
      );

      if (value?.archiveID) {
        this.applyData.user.user_card_id = value?.archiveID;
      } else if (value == "delete") this.applyData.user.user_card_id = "";
    },
    applySubmit() {
      const data = {
        ...this.applyData.company,
        ...this.applyData.user
      } as any;
      delete data.email;
      console.log(
        "🚀 ~ file: CreateCompany.vue ~ line 446 ~ applySubmit ~ payload",
        data
      );

      if (data) {
        CloudFun.send("success", {
          subject: "申請送出",
          content: "成功(API 缺欄位，目前等待調整中....)"
        });
        this.step = 4;
        return;
        this.$model.dispatch("vendor/apply", data).then(
          response => {
            console.log(
              "🚀 ~ file: Create.vue ~ line 471 ~ applySubmit ~ response",
              response
            );

            if (response.status && response.payload.isPassed) {
              CloudFun.send("success", {
                subject: "申請送出",
                content: "成功"
              });
              this.step = 4;
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: response.message
              });
            }
          },
          reason => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: reason
            });
          }
        );
      }
    }
  }
});
